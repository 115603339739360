export default {

  // 大分類//
  nuwaIvf: 'Nuwa Fertility Center',
  nuwaTcm: 'Nuwa TCM Center',
  nuwaLabs: 'Nuwa Lab',
  nuwaIvfNew: 'Dianthus Daan Nuwa Fertility',
  nuwaIvfNew2: 'Dianthus Fertility Appointment at Nuwa, Daan',
  nuwaTeams: 'Nuwa Female Doctors',
  nuwaStories: 'Nuwa Stories',
  news: 'Nuwa News and Instruction',
  aboutNuwaPublic: 'Nuwa Healthcare',
  visa: 'Nuwa Ihc',
  introTcm: 'TCM Treatment',
  aboutNuwa: 'About Nuwa',
  tcmTitle: 'NUWA TCM',

  // 標語 //
  DoctorsSlogan: 'Your NUWA, Your Pregnancy',
  tcmContent: 'Physicians in NUWA TCM with more than 20 years of experience, specializing in infertility, egg breeding, intimal thickening, pre and post implantation, miscarriage, pregnancy, premenstrual discomfort, menstrual pain, allergic improvement, postpartum, weight loss , sleep disorders and other traditional Chinese medicine conditioning.With a independent acupuncture room to maintain a high degree of privacy during the treatment, NUWA TCM Center provide a comfortable environment for medicine consultation to help female friends feel more comfortably and smoothly on the road to the pregnancy and recuperation.',
  ivfContent: "Humanity, Technology and Care are the cores of the NUWA Fertility Center. Based on female-oriented integrated healthcare and an all-female medical team, Nuwa team wants to build individualized, comprehensive care plans based on patients' unique needs. Nuwa team not only cares about each patient but also provide doctors with empathy. Nuwa team want to be warm ,close to each woman and help women to realize the dream of parenthood.",

  // button //
  readAritcal: 'Read More',
  more: 'Learn More',
  introTeams: 'Introduction',
  lookingTeams: 'Our Team',
  lookingDoctors: 'Doctors',
  lookingMore: 'Learn More',
  quicklink: 'Consultation',
  moreNuwa: 'More',
  bookingNow: 'Request an appointment',
  reservation: 'Request an appointment',
  share: 'Share',
  sure: 'Confirm',
  message: 'Message',
  copyComplete: 'Copy complete',
  back: 'Back',
  backToStory: 'Back to Nuwa Stories',
  aboutTreatment: 'Treatment',
  ivfTreatment: 'Fertility Treatment',
  tcmTreatment: 'TCM Treatment',
  contact: 'One-On-One Service',

  // 地址 //
  ivfAddress: '15F., No.149, Sec.3, Xinyi Rd., Da-an Dist., Taipei City 106, Taiwan',
  tcmAddress: 'Address : 14F-1., No.149, Sec.3, Xinyi Rd., Da-an Dist., Taipei City 106, Taiwan',

  // title //
  oneForOneService: 'One-On-One Service',

  // time //
  am: 'Morning',
  noon: 'Noon',
  pm: 'Afternoon',
  night: 'Night',
  betweenNight: 'Night',
  monday: 'Mon',
  thursday: 'Thurs',
  friday: 'Fri',
  saturday: 'Sat',

  // ------header------//

  nuwaIvfHeader: 'Fertility Center',
  nuwaTcmHeader: 'TCM Center',
  nuwaLabsHeaderHtml: 'Lab',
  nuwaLabsHeader: 'Lab',
  nuwaTeamsHeader: 'Doctors',
  nuwaStoriesHeader: 'Stories',
  newsHeader: 'News and Instruction',
  aboutNuwaPublicHeader: 'Healthcare',
  aboutNuwaHeader: 'About us',
  internationalMedicalCenterHeader: 'International Healthcare Center',

  language: 'Language',
  internationalMedicalCenter: 'International Healthcare Center',
  booking: 'Appointment',
  login: 'Login',
  logout: 'Logout',

  // ------footer------//
  address: 'Address',
  contactText: 'Consultation',
  // privact and reserved
  privacy: 'privacy',
  reservedText: '本網站所有內容、資訊及相關權利均為禾馨宜蘊婦產科診所以及宜蘊中醫診所所有或被授權，任何人未經事前書面同意，不得為任何重製、轉載、散布、改作、公開傳輸、公開發表等侵害智慧財產權或其他權利之行為，並不得將網站全部或部分內容轉載於任何形式媒體或社群。本網站委由宜蘊生醫股份有限公司維護管理。 ©2021 NUWA FERTILITY CENTER ALL RIGHTS RESERVED.',
  privacyMainTitle: 'Welcome to the website of the page. To set your mind at ease as you use the services and information of this site, we would like first to explain our policy on the protection of your privacy rights. To ensure your rights, please carefully read the following:',
  privacyTitle1: '1.Scope of the Policy',
  parivacyContent1: 'The Policy governs how this site processes the individual personal data collected while you are visiting the site. The Policy does not apply to related web links and extends only to personnel entrusted by this site and to those involved in its management.',
  privacyTitle2: '2.Data Collection and Use',
  privacyContent2: 'In order to offer you the best possible interactive service, you may be asked to provide and/or allow some personal information to be collected as follows: When you use the interactive function of the customer service e-mail box or the questionnaire survey form, information you provide, like your name, e-mail address, contact information and length of use, may be retained. When you are browsing, the server may automatically make a record of your activity, including the IP address of the equipment you use to access the Internet, the length of time you spend at the site, what browser you use and the data you have browsed and accessed. We use this information as reference material to further develop our website services, and the information is for internal use only. Under no circumstances is it made available to the outside. In order to provide the best service, we may compile and analyze the content of questionnaire surveys. In addition to use in internal studies, the statistics derived from these analyses and the analytical commentary may be made public in cases where we deem this necessary, but such information will not involve data on specific individuals. Unless we receive your agreement or are directed by special legal regulations, this site will under no circumstances make your personal data available to a third party or use these data for a purpose other than that for which they were collected.',
  privacyTitle3: '3.Data Protection',
  privacyContent3: 'Our sites computer mainframe is equipped with firewall, anti-virus and other information security applications, and we have taken all necessary information security measures. In addition, we have added very strict measures to protect the site itself and your personal information. Your personal information may be accessed only through authorized personnel. All personnel who process this data have signed a secrecy agreement. If anyone breaks this agreement, he or she will be punished to the full extent of the relevant laws. If for business reasons, it becomes necessary to entrust the provision of this service to a related office of this Department, this site will strictly require that it honor the obligations of the secrecy agreement and will adopt the necessary review procedures to assure that this obligation is indeed carried out.',
  privacyTitle4: '4.Related Website Links',
  privacyContent4: 'Our web page provides links to other websites, and you can select these websites through our links. However, our sites policy on the protection of privacy rights does not apply to the linked sites. You must consult the linked site for information on their policy.',
  privacyTitle5: '5.Sharing Personal Information with Third Party',
  privacyContent5: 'This website will not provide, exchange, lease or sell your personal information to other persons, organizations, private corporations or public institutions, unless due to legal or contractual obligations.The“legal or contractual obligations” in the previous paragraph to share your personal information with a third party include, but are not limited to, the following circumstances: (1) to cooperate with legal investigation initiated by judicial authorities; (2) to cooperate with competent authorities in discharge of their duties to investigate or use (such as financial audit carried out by the National Audit Office of the R.O.C. or a certified public accountant); (3) a good-faith belief that the disclosure of your personal information is required by law; (4) to determine your identity, to provide contact information or to take legal actions, when necessary, if you as a website visitor violate the service terms of III, or are likely to jeopardize or interfere with the interests of III or result in damages to any person; (5) to fulfill contractual obligations under outsourcing contracts.',
  privacyTitle6: '6.Use of Cookies',
  privacyContent6: 'To provide you the best possible service, we may download our Cookie into your computer for future use. If you do not wish to accept this Cookie, you can select the high security level on your browser, which will enable you to reject it. However, this may prevent you from using some functions of our site normally.',
  privacyTitle7: '7.Policy Revisions',
  privacyContent8: 'This sites policy on the protection of privacy rights will be revised as circumstances demand, and such revisions will be published on the site.',
  // privact and reserved end

  browser: '最佳瀏覽體驗建議瀏覽器: Edge、Firefox、Chrome、Safari ( 螢幕設定最佳顯示效果為1920*1080 )',

  // ------index------//

  // 療程
  introIvf: 'Fertility Treatment',
  introlabs: 'NUWA Lab',
  aboutIntroText: "NUWA female medical team is based on female-oriented integrated healthcare , FEMTECH's concept, the latest technology, and evidence-based medical nutrition treatment. Our purpose is to increase the success rate of IVF and provide the best IVF services in Asia-Pacific area . Not only the support of medical technology but also the psychological care, NUWA healthecare team will provide a personalized plan for you.",
  labsContent: 'Expert embryo laboratory and professional embryologists are the keys to the success of IVF. From sperm and egg integration to embryo development ,Nuwa laboratory team work together to helps you to realize your dream of parnethood.',
  // 故事
  storiesConetnt: 'Although it is very hard on the way to the pregnancy, NUWA will always be with you and help to realize your dream of parenthood.',
  // imgLink區塊

  // ------AboutIvf & Tcm & Labs------//
  ivfTitle: 'Fertility Treatment',
  aboutIvfIntro: "Humanity, Technology and Care are the cores of the NUWA Fertility Center. Based on female-oriented integrated healthcare and an all-female medical team, Nuwa team wants to build individualized, comprehensive care plans based on patients' unique needs. Nuwa team not only cares about each patient but also provide doctors with empathy. Nuwa team want to be warm ,close to each woman and help women to realize the dream of parenthood.",
  ivfTeamsTitle: 'Fertility Care Team',
  ivfTeamsIntro: "NUWA Fertility Center based on female-oriented integrated healthcare and an all-female medical team, Nuwa team wants to build individualized, comprehensive care plans based on patients' unique needs. Leads by the former vice director of Department of Reproductive Center in Taipei Medical University Hospital and director of Shuang Ho Hospital. Nuwa team also want to be warm ,close to each woman and help women to realize the dream of parenthood.",
  aboutTcmIntro: 'NUWA TCM physicians with more than 20 years of experience, specializing in infertility, egg breeding, intimal thickening, pre and post implantation, miscarriage, pregnancy, premenstrual discomfort, menstrual pain, allergic improvement, postpartum, weight loss , sleep disorders and other traditional Chinese medicine conditioning.With a independent acupuncture room to maintain a high degree of privacy during the treatment, NUWA TCM Center provide a comfortable environment for medicine consultation to help female friends feel more comfortably and smoothly on the road to the pregnancy and recuperation.',
  aboutLabsIntro: 'Our embryologists here perform a wide range of fertility services.',
  tcmTeamsTitle: 'TCM Team',
  tcmTeamsIntro: 'TCM helps you to have a good pregnancy. The director of NUWA TCM Clinic is Chen Yujuan, the former director of the Department of Gynecology of Taipei Medical University Hospital. By witnessing the progress of reproductive technology over the past two decades. The visitors per month exceeds 1,000 and the number of diagnosis and treatment visitors reaches 10,000.',
  labsTitle: 'About NUWA Lab',
  labsIntro: "Human resources are the important asset of an enterprise; senior embryologists are also the important asset of NUWA Team. The NUWA Lab is composed of a group of master and doctoral embryologists who are experienced and agree with NUWA Team's concept. IVF treatments relies on the delicate teamwork, especially IVF laboratories which must combine advanced life science technology and high-quality environmental engineering. NUWA Lab is based on professional technology and with three advantages: top embryologists, AI incubating and safe environment. NUWA will always be with you and ready safety care for you.",
  labsTeamsTitle: 'NUWA Lab',
  labsTeamsIntro: 'Our embryologists here perform a wide range of fertility services.',

  // ------About Nuwa------//
  humanity: 'Humanity',
  technology: 'Technology',
  care: 'Care',
  nuwaTeamsContent: 'NUWA female medical team is based on female-oriented integrated healthcare , the latest technology, and evidence-based medical nutrition treatment to increase the success rate of IVF. With superior international capabilities, our purpose is to provide the best IVF services in Asia-Pacific area .',
  aboutLabs: 'Our embryologists here perform a wide range of fertility services.',
  advantage: 'Why NUWA',
  advantageContent: 'We want to build the first all-female medical team in Asia. The NUWA Team work together to provide comprehensive and individualized fertility care.',
  womenCare: 'NUWA Female Medical Team',
  tcmCare: 'NUWA TCM',
  customizeCare: 'Individualized Fertility Care',
  psyCare: 'Consultation',
  technologyCare: 'Technological Care Platform',
  vipCare: 'VIP Clinic',
  labsCare: 'Advanced Lab',
  femTech: 'FemTech',
  location: 'Location',
  ivfLocation: '15F, Xinyi Building, Taipei Agricultural Association, Exit 1, R05 Daan (Tamsui-Xinyi Line)',
  openTime: 'Opening',
  tcmLocation: '14F, Xinyi Building, Taipei Agricultural Association, Exit 1, R05 Daan (Tamsui-Xinyi Line)',
  parking: 'Parking',
  parkingLocation: 'Fujong Park (No. 11, Ln. 340, Sec. 1, Fuxing S. Rd., Da’an Dist., Taipei City)',
  parkingLocation_2: 'iRent Park(Ln. 340, Sec. 1, Fuxing S. Rd., Da’an Dist., Taipei City)',
  parkingLocation_3: 'Daan Car Park (No. 12, Aly. 6, Ln. 166, Sec. 3, Xinyi Rd.) with 526 parking spaces.',
  endText: 'For the moment',
  // ------International------//
  visaProcess: 'Visa Application',
  professional: 'Reliable',
  oneForOne: 'One-On-One',
  personService: 'Client Service',
  medicalService: 'Medical Service',
  englishService: 'English Service',
  contactUs: 'Contact us',
  readyDocuments: 'Preparation',
  provideDocuments: 'Information',
  contactUsAndComing: 'Contact Us to Realize the Dream of Parenthood',
  oneForOneServiceContent: 'International Outpatient Service and Out-of-pocket service with efficiency and convenience without language barrier',
  thisIsNotInsurance: 'Out-of-pocket Service',
  dealVisaOrEntry: 'Visa appliciation',
  dealVisaOrEntryContent: 'Please contact with NUWA Healthcare Team, our team will help you to apply for the medical visa.',
  dealVisaAndSteps: 'How to apply for the Medical Visa',
  stepOneMidTitle: 'The Parenthood, The First Step',
  stepOneInfo: 'Please contact with NUWA Healthcare Team, our team will evaluate your condition and arrange the treatments for you. We also can help you to apply for the medical visa.',
  stepTwoeMidTitle: 'The Parenthood, The Documents',
  stepTwoInfoParam1: 'We will need some of the documents to evaluate your condition and arrange your schedule.',
  stepTwoInfoParam2: 'Passport file',
  stepTwoInfoParam3: 'Certificate of marriage',
  stepTwoInfoParam4: 'Medical reports or image.',
  stepThreeeMidTitle: 'Submit for the Parenthood',
  stepThreeInfoParam1: 'With the documents',
  stepThreeInfoParam2: 'We will contact with you in 1 to 3 days',
  stepThreeInfoParam3: 'Residents in china will need the EXIT & ENTRY PERMIT TAIWAN REPUBLIC OF CHINA to enter Taiwan',
  stepThreeInfoParam4: 'Due to the policy in Taiwan, the certificate of marrige will be necessary.',
  // ------News------//
  all: 'all',
  notFound: 'notFound',
  // ------Registration------//
  onlineBooking: 'Request an appointment',
  selectList: 'Request by department',
  specialOutpatient: 'VIP Clinic',
  OutpatientInfo: 'Request an appointment through our Online registration system to save your time.',
  amOutpatient: 'Morning Clinic',
  noonOutpatient: 'Afternoon Clinic',
  nightOutpatient: 'Night Clinic',
  bookingInfo: 'To book an appointment through online registration system for the next 30 days, open from midnight 30 days before. (Ex. To register for an appointment on Apr. 15th, please book after Mar. 14th.) The service hours may vary depending on the condition in the hospital.',
  bookingInfoPrecaution: 'Required to know',
  bookingInfoPrecautionContent1: 'Please report in at the registration & cashier counter upon arrival. Must register before 11:00 for morning sessions ,16:00 for afternoon sessions and 20:00 for night sessions.',
  bookingInfoPrecautionContent2: 'Please note that the charges may differ due to your condition and the NUWA TCM is out-of-pocket expenses.',
  bookingInfoPrecautionContent3: 'Please note that if you do not have passport,ID card or NHI card in Taiwan,the charges may differ due to the policy in NUWA.',
  bookingInfoPrecautionContent4: 'Please feel free to contact us for further information.',
  bookingInfoPrecautionContent5_1: 'Registration Time',
  bookingInfoPrecautionContent5_2: 'Morning Clinic must before 10:00.',
  bookingInfoPrecautionContent5_3: 'Afternoon Clinic must befroe 15:30',
  bookingInfoPrecautionContent5_4: 'Night Clinic must befroe 19:00',
  bookingInfoPrecautionContent5_5: 'If you need to cancel the appointment on the day of consultation , please contact with us.',
  bookingInfoPrecautionContent6_1: 'Online registration system available 24/7',
  bookingInfoPrecautionContent6_2: 'You may dial the following telephone numbers when you have any questions about seeing a doctor, receiving treatment, or other related information.',
  clinicCallNumberRule: 'Policy to Follow to Visit a Doctor',
  clinicCallNumberContent1: 'Must register before visiting the doctor and wait your number to be called.',
  clinicCallNumberContent2: 'Please visit our counter if you miss your appointment number to be called.',
  clinicCallNumberContent3: 'You need wait for another 2 patients if you miss your number to be called.',
  clinicTcmNotify: 'NUWA TCM In-person and Specialty Care Appointments During COVID-19',
  clinicTcmNotifyContent_1: 'For the better healthcare quality, we will need you to prepare your medical records to us.',
  clinicTcmNotifyContent_2: 'To save your time, please try to decide on a physician as well as a desired date and time.',
  clinicTcmNotifyContent_3: 'We are ready to safety care for you, please be patient. The consultation time may differ due to each patient’s condition.',
  clinicTcmNotifyContent_4: 'Everyone – patients, visitors (when permitted) and staff – wears a mask. We might need you take the pictures of the whole face and tongue at home before visiting.',
  nuwaSpecialOutpatient: 'NUWA VIP clinic',
  nuwaSpecialOutpatientInfo_1: 'In order to pursue excellent reproductive medicine services, NUWA VIP Clinic allows you to arrange your infertility treatment easily and quickly',
  nuwaSpecialOutpatientInfo_2: 'NUWA Healthcare will personalize your own VIP treatments and services.',
  clinicServiceTitle: 'The NUWA VIP clinic',
  clinicServiceContent: 'Simply to make a phone call to enjoy your personalized infertility treatment in the comfortable and quiet place which provided by the NUWA Healthcare team.',
  phoneBooking: 'Request a VIP appointment',
  phoneBookingContent: 'Contact with us to personalized your treatment.',
  personReception: 'Personal Reception',
  personReceptionContent: 'We will contact with you to comfirm your arrival time.',
  vipSpace: 'VIP Room',
  vipSpaceContent: 'Your own room',
  accompany: 'Your personal assisstance.',
  accompanyContent: 'The NUWA team will personalize your won service.',
  personReceptionContent_2: 'Your own treatment, your won consultant,',
  clinicRule: 'About the NUWA VIP clinic',
  clinicRuleContent1: 'Advance reservations only.',
  clinicRuleContent2: 'The NUWA VIP clinic will contact with you to confirm your schedule.',
  clinicRuleContent3: 'The NUWA VIP clinic is out-of-pocket service.',
  clinicRuleContent4: 'Please note that the consutaltion fee starts at NTD 2,000 / 30 mins.( The actual may differ according to your condition.)',
  specialClinicPhoneNumber: 'Request a VIP appointment.',
  local: 'Resident in Taiwan.',
  oversea: 'Foreign nationals',
  // ------RegistrationChoose------//
  schedule: 'Schedule',
  doctor: 'Physician',
  lastWeek: 'Previoust Week',
  nextWeek: 'Next Week',
  overTime: 'Timed out',
  book: 'Book',
  return: 'Return',
  confirmOutpatient: 'Confirm your appointment',
  name: 'Name',
  namePlaceHolder: 'Enter your name',
  date: 'Date of birth',
  uid: 'ID No.',
  uidInfo: 'Choose Passport No / ARC No. to enter',
  passportNum: 'Passport No./ ARC No.',
  passportNumInfo: 'Or ID No. ( Choose one to fill in)',
  contactNumber: 'Contact Number',
  contactPlaceholder: 'Enter your phone number',
  emailPlaceholder: 'Enter your E-mail',
  verification: 'Verification Code',
  verificationPlaceholder: 'Enter Verification Code',
  sendVerificationByTextMsg: 'Send By Text Message',
  sendVerificationByMail: 'Send By Mail',
  verificationInfo: 'Enter all the number counted to get the code',
  verificationAnswerPlaceholder: 'Enter',
  getVerification: 'Get Code',
  nextStep: 'nextStep',
  changePassword: 'Change Password',
  newPasswordInfo: 'The password must be eight characters or more and contain at least one uppercase character, at least one lowercase character and at least one number.',
  newPassword: 'New Password',
  comfirmNewPassword: 'Comfirm your new password',
  saveMod: 'Save',
  hello: 'hello',
  overSeaServiceInfo: 'Please contact with us if you are not the resident in Taiwan.',
  IvfClinic: 'NUWA Fertility Center appointment',
  TcmClinic: 'NUWA TCM Center appointment',
  passwordError: 'The password must be eight characters or more and contain at least one uppercase character, at least one lowercase character and at least one number.',
  passwordComfirmError: 'Please confirm your password.',
  modifySuccess: 'Successful',
  noDoctor: "Cannot get the physician's information",
  selectDoctorInfo: 'Select physician',
  selectTimeInfo: 'Select Time',
  noDoctorSchedule: 'Cannot get the schedule',
  customSWALSelectDoctor: 'Select physician',
  customSWALSelectTime: 'Select Time',
  customSWALFillName: 'Please enter your name',
  customSWALFillDate: 'Please enter your DOB',
  customSWALFillPid: 'Please enter your ID No. or Passport No. / ARC No.',
  customSWALPidError: 'ID No. incorrect',
  customSWALSelectNumberLocal: 'Please select the area code',
  customSWALFillNumber: 'Please enter your mobile phone numner',
  customSWALFillMail: 'Please enter your e-mail',
  customSWALClickVerification: 'Get the verification code',
  customSWALFillVerification: 'Please enter the verification code',
  customSWALMailError: 'Please enter the email',
  customSWALPhoneError: 'Please enter the mobile phone number',
  sendingVerification: 'The verification code has been send',
  successfulRegistration: 'Successful Registration',
  // ------RegistrationConfirm------//
  comfirmClinic: 'Please confirm the appointment',
  doNotRefresh: 'Pleease do not refresh the page',
  modifyOutpatient: 'Change your appointment',
  refreshError: 'Error, please make the appointment again.',
  lostColumns: 'Error, please make the appointment again.',
  // ------RegistrationComplete------//
  registrationClinic: 'Appointment',
  selectDoctorAndTime: 'Select',
  comfirmRegistration: 'Confirm',
  registrationComplete: 'Success',
  registrationSuccess: 'Success',
  registrationDate: 'Date',
  registrationTime: 'Time',
  registrationCategory: 'Status',
  room: 'Clinic',
  roomNum: 'Clinic Number',
  number: 'Number',
  yourName: 'Name',
  passportOrEntryPassNum: 'Passport No,/ARC No.',
  comingInfo: 'Please bring the NHI card or identification documents',
  backToIndex: 'Homepage',
  myregistration: 'My appointment',
  ivf: 'Infertility',
  tcm: 'TCM',
  one: 'One',
  two: 'Two',
  three: 'Three',
  four: 'Four',
  five: 'Five',
  sex: 'Six',
  seven: 'Seven',
  eight: 'Eight',
  dataError: 'Error',
  // ------memberSignup------//
  signup: 'Member Registation',
  gender: 'Gender',
  female: 'Female',
  male: 'Male',
  accout: 'Accout',
  pidOrPassportNum: 'ID No./Passport No.',
  password: 'Password',
  passwordPlaceholder: 'Password must between 8 to 20 characters long. Including a capital letter',
  confirmPassword: 'Confirm the password',
  refillPasswordPlaceholder: 'Enter the password again.',
  getVerificationByTextMail: 'To get the SMS verification code',
  getVerificationByEMail: 'To get the Email verification code',
  agree: 'Agree',
  areadyHasAccout: 'You had been the member already.',
  customSWALInputName: 'Please enter your name',
  customSWALInputBirth: 'Please enter your DOB',
  customSWALInputGender: 'Please enter your gender',
  customSWALInputAccount: 'Please enter your account',
  customSWALInputPassword: 'Please enter your password',
  customSWALComfirmPassword: 'Please confirm your password',
  customSWALInputNumber: 'Please enter the contact number',
  customSWALInputCorrectNumber: 'Please enter the contact number correctly',
  customSWALInputLocal: 'Please enter the area code',
  customSWALAgreePrivacy: 'Please check the Privacy policy.',
  // ------memberLogin------//
  memberLogin: 'Login',
  loginError: 'Please check your email address and password and try again',
  loginAccountPlaceholder: 'The account will be the ID No.',
  loginPasswordPlaceholder: 'Please enter the password.',
  rememberMe: 'Remember me',
  fogotPassword: 'fogot your password',
  // ------memberHome------//
  myInfo: 'Information',
  pleaseLogin: 'Please log in',
  // ------fogotPassword------//
  fogotPasswordTitle: 'Please enter the personal information to reset the password.',
  fogotPasswordInfo: 'Please enter the personal information , the authentication letter will send to your eamil.',
  pleaseInputBirthDay: 'Please enter your DOB',
  resetPasswordTextMail: 'Re-set the password by SMS message',
  resetPasswordEmail: 'Re-set the password by mail',
  hasAccount: 'Member already?',
  mailSendingTitle: 'The authentication letter had been sent by email.',
  mailSendingContent: 'The authentication letter had been sent by email,<br>please follow the steps to reset the password.',
  textMailSendingTitle: 'The authentication letter had been sent by SMS message.',
  textMailSendingContent: 'The authentication letter had been sent by SMS message to  09xxxxxxxx,<br> please follow the steps to reset the password.',
  memberNotFound: 'Member not found',
  complete: 'Complete',
  // ------memberData------//
  helloGuest: 'Hello Guest',
  // ------memberReserveList------//
  cancelReserve: 'Cancel appointment',
  pidAndPassportNum: 'Passport No./ ARC No.',
  noRigistration: 'Empty',
  comfirmCancel: 'Comfirm to Cancel',
  cancelSucsess: 'The appointment has been canceled.',
  // ------memberDataInfo------//
  patientNo: 'Chart No.',
  phoneNumber: 'Phone No.',
  birthDay: 'DOB',
  memberDataPid: 'ID No.',
  oldPassword: 'Old Password',
  passwordLengthError: 'Password must be at least 8 characters long.',
  inputOldPassword: 'Please enter the old passowrd.',
  // 團隊分類
  生殖醫療團隊: 'Fertility Medical Team',
  麻醉醫師團隊: 'Anesthesiologist Team',
  乳房外科團隊: 'Breast Surgery Team',
  護理諮詢團隊: 'Consultation Team',
  中醫醫療團隊: 'TCM Team',
  藥師: 'Pharmacist',
  實驗室團隊: 'Lab Team',
  // 我的預約
  roomOne: 'Number one',
  roomTwo: 'Number two',
  roomThree: 'Number three',
  roomFour: 'Number four',
  roomFive: 'Number five',
  roomSix: 'Number six',
  roomSeven: 'Number seven',
  roomEight: 'Number eight',
  // 後端回傳
  reserved401: 'You had already made an appointment today',
  reserved402: 'You had already made an appointment in this week',
  verify400: 'Verify failed',
  verify401: 'Please enter verification code',
  // 我的預約
  num: 'number',
};
