import Vue from 'vue';
import Vuex from 'vuex';
import VueCookies from 'vue-cookies';

Vue.use(Vuex, VueCookies);

export default new Vuex.Store({
  state: {
    member: {
      id: null,
      account: null,
      name: null,
      birthday: null,
      api_token: null,
      email: null,
      phone: null,
      is_first: null,
    },
    appointmentData: {
      item_id: null,
      devision_id: null,
      doctor_code: null,
      appointment_day: null,
      sid: null,
      shift_code: null,
      clinic: null,
      doctor_name: null,
    },
    signUpFormData: {
      account: null,
      name: null,
      birthday: null,
      phone: null,
      email: null,
      country_code: null,
      verify_code: null,
      send_way: null,
    },
    appointmentComplete: {
      serial_no: null,
    },
    socialLightbox: {
      status: false,
      type: 1,
    },
    bodyLock: false,
    lang: null,
    langUUID: null,
  },
  getters: {
    getMemberAll(state) {
      return state.member;
    },
    getAppointmentData(state) {
      return state.appointmentData;
    },
    getAppointmentComplete(state) {
      return state.appointmentComplete;
    },
    getSignUpFormData(state) {
      return state.signUpFormData;
    },
    getLanguage(state) {
      return state.lang;
    },
    getLangUUID(state) {
      return state.langUUID;
    },
  },
  mutations: {
    initMeb(state) {
      if (VueCookies.isKey('id')) {
        state.member.id = VueCookies.get('id');
      }
      if (VueCookies.isKey('account')) {
        state.member.account = VueCookies.get('account');
      }
      if (VueCookies.isKey('name')) {
        state.member.name = VueCookies.get('name');
      }
      if (VueCookies.isKey('birthday')) {
        state.member.birthday = VueCookies.get('birthday');
      }
      if (VueCookies.isKey('api_token')) {
        state.member.api_token = VueCookies.get('api_token');
      }
      if (VueCookies.isKey('email')) {
        state.member.email = VueCookies.get('email');
      }
      if (VueCookies.isKey('phone')) {
        state.member.phone = VueCookies.get('phone');
      }
    },
    logOut(state) {
      state.member.id = null;
      state.member.account = null;
      state.member.name = null;
      state.member.birthday = null;
      state.member.api_token = null;
      state.member.email = null;
      state.member.phone = null;
      state.member.is_first = null;
      VueCookies.remove('id');
      VueCookies.remove('account');
      VueCookies.remove('name');
      VueCookies.remove('birthday');
      VueCookies.remove('api_token');
      VueCookies.remove('email');
      VueCookies.remove('phone');
      VueCookies.remove('is_first');
    },
    logIn(state, data) {
      // state.member.id = data.member.id;
      state.member.account = data.member.account;
      state.member.name = data.member.name;
      state.member.birthday = data.member.birthday;
      state.member.api_token = data.api_token;
      state.member.email = data.member.email;
      state.member.phone = data.member.phone;
      state.member.is_first = data.member.is_first;
      console.log('s', state.member);
      VueCookies.set('id', data.member.id);
      VueCookies.set('account', data.member.account);
      VueCookies.set('name', data.member.name);
      VueCookies.set('birthday', data.member.birthday);
      VueCookies.set('api_token', data.api_token);
      VueCookies.set('email', data.member.email);
      VueCookies.set('phone', data.member.phone);
      VueCookies.set('is_first', data.member.is_first);
    },
    setAppointmentData(state, data) {
      state.appointmentData.item_id = data.item_id;
      state.appointmentData.devision_id = data.devision_id;
      state.appointmentData.doctor_code = data.doctor_code;
      state.appointmentData.appointment_day = data.appointment_day;
      state.appointmentData.sid = data.sid;
      state.appointmentData.shift_code = data.shift_code;
      state.appointmentData.clinic = data.clinic;
      state.appointmentData.doctor_name = data.doctor_name;
    },
    setAppointmentComplete(state, data) {
      state.appointmentComplete.serial_no = data.data.serial_no;
    },
    setSignUpFormData(state, data) {
      state.signUpFormData.account = data.account;
      state.signUpFormData.name = data.name;
      state.signUpFormData.birthday = data.birthday;
      state.signUpFormData.phone = data.phone;
      state.signUpFormData.email = data.email;
      state.signUpFormData.country_code = data.country_code;
      state.signUpFormData.verify_code = data.verify_code;
      state.signUpFormData.send_way = data.send_way;
    },
    clearAppointmentData(state) {
      state.appointmentData.item_id = null;
      state.appointmentData.devision_id = null;
      state.appointmentData.doctor_code = null;
      state.appointmentData.appointment_day = null;
      state.appointmentData.sid = null;
      state.appointmentData.shift_code = null;
      state.appointmentData.clinic_code = null;
      state.appointmentData.doctor_name = null;
    },
    clearAppointmentComplete(state) {
      state.appointmentComplete.serial_no = null;
    },
    setSocialLightbox(state, data) {
      state.socialLightbox.status = data.status;
      state.socialLightbox.type = data.type;
    },
    setBodylock(state, data) {
      state.bodyLock = data;
    },
    setLanguage(state, data) {
      state.lang = data;
    },
    setLangUUID(state, data) {
      state.langUUID = data;
    },
  },
  actions: {
  },
  modules: {
  },
});
