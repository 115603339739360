<template>
  <section id="about">
    <div class="about_bar w1400">
      <div class="about_bar-pagination">
        <div
        style="text-align: left; padding-left: 30px;"
        :class="[{active: nowAboutBar == 'ivf'}, {en: getLanguage === 'en-US'}]"
        @click="changeNowAboutBar('ivf')">
          {{$t('introIvf')}}
        </div>
        <div
        style="text-align: left; padding-left: 30px;"
        :class="[{active: nowAboutBar == 'fertility'}, {en: getLanguage === 'en-US'}]"
        @click="changeNowAboutBar('fertility')">
          {{$t('introTcm')}}
        </div>
        <div
        style="text-align: left; padding-left: 30px;"
        :class="[{active: nowAboutBar == 'labs'}, {en: getLanguage === 'en-US'}]"
        @click="changeNowAboutBar('labs')">
          {{$t('introlabs')}}
        </div>
      </div>

      <div class="swiper-button-prev"></div>

      <swiper
      class="about_bar-content"
      v-if="nowAboutBar == 'ivf'"
      :options="swiperOption">
        <swiper-slide
        class="item"
        v-for="(item, key) in aboutBar.ivf"
        :key="key">
          <router-link :to="item[0]">
            <div class="img_outer">
              <img :src="item[2]" alt="" />
            </div>
            <p class="txt-dark_gray txt-medium" v-html="item[1]"></p>
          </router-link>
        </swiper-slide>
      </swiper>

      <swiper
      class="about_bar-content"
      v-if="nowAboutBar == 'fertility'"
      :options="swiperOption">
        <swiper-slide
        class="item"
        v-for="(item, key) in aboutBar.fertility"
        :key="key">
          <router-link :to="item[0]">
            <div class="img_outer">
              <img :src="item[2]" alt="" />
            </div>
            <p class="txt-dark_gray txt-medium">{{item[1]}}</p>
          </router-link>
        </swiper-slide>
      </swiper>

      <swiper
      class="about_bar-content"
      v-if="nowAboutBar == 'labs'"
      :options="swiperOption">
        <swiper-slide
        class="item"
        v-for="(item, key) in aboutBar.labs"
        :key="key">
          <router-link :to="item[0]">
            <div class="img_outer">
              <img :src="item[2]" alt="" />
            </div>
            <p class="txt-dark_gray txt-medium">{{item[1]}}</p>
          </router-link>
        </swiper-slide>
      </swiper>

      <div class="swiper-button-next"></div>

    </div>

    <div class="about_intro w1200">
      <div class="col50">
        <!-- <h3 class="eng">About NUWA</h3> -->
        <h5>
          {{$t('aboutNuwaPublic')}}
          <img class="deco_flower" src="@/assets/img/deco_flower_white.svg" alt="">
        </h5>
      </div>
      <div class="col50">
        <p class="small">
          {{aboutIntroText}}
        </p>
      </div>
    </div>

    <div class="about_content">
      <router-link :to="item.path"
      class="about_content_item"
      v-for="(item, key) in aboutContent"
      :key="key"
      :style="{backgroundImage: `url(${item.backgroundImage})`}">
        <div class="about_content_item-cover"></div>
        <div class="about_content_item-container">
          <div class="about_content_item-title">
            <h5 class="eng">{{item.zh}}</h5>
            <p v-if="getLanguage !== 'en-US'">{{item.en}}</p>
            <div class="about_content_item-content">
              <div class="about_content_item-content-hr"></div>
              <p class="small">{{item.content}}</p>
              <div class="btn btn-solid">{{$t('introTeams')}}</div>
            </div>
          </div>
        </div>
      </router-link>
    </div>
  </section>
</template>

<script>
import {
  getProcreationArticles,
  getTraditionalArticles,
  getLabsArticles,
} from '@/lib/public';
import { mapMutations, mapGetters } from 'vuex';

import img1 from '@/assets/img/index/about_IVF_Center_bg_new.jpg';
import img2 from '@/assets/img/index/about_fertility_new.jpg';
import img3 from '@/assets/img/index/about_Lab_bg_new.jpg';

export default {
  name: 'IndexAbout',
  data() {
    return {
      swiperOption: {
        loop: false,
        slidesPerView: 6,
        spaceBetween: 0,
        allowTouchMove: true,
        navigation: {
          nextEl: '#about .swiper-button-next',
          prevEl: '#about .swiper-button-prev',
        },
        // pagination: {
        //   el: '#kv .swiper-pagination',
        //   clickable: true,
        // },
        breakpoints: {
          1440: {
            slidesPerView: 5.5,
            spaceBetween: 40,
          },
          1366: {
            slidesPerView: 5,
            spaceBetween: 40,
          },
          1280: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          680: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          520: {
            slidesPerView: 2.7,
            spaceBetween: 40,
          },
          420: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
        },
      },
      nowAboutBar: 'ivf',
      aboutBar: {
        ivf: [],
        fertility: [],
        labs: [],
      },
      aboutIntroText: this.$t('aboutIntroText'),
      aboutContent: [
        {
          path: '/about/Ivf',
          backgroundImage: img1,
          en: 'NUWA FERTILITY',
          zh: this.$t('nuwaIvf'),
          content: this.$t('ivfContent'),
        },
        {
          path: '/about/tcm',
          backgroundImage: img2,
          en: 'NUWA TCM CENTER',
          zh: this.$t('nuwaTcm'),
          content: this.$t('tcmContent'),
        },
        {
          path: '/about/lab',
          backgroundImage: img3,
          en: 'NUWA LAB',
          zh: this.$t('nuwaLabs'),
          content: this.$t('labsContent'),
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'getLangUUID',
      'getLanguage',
    ]),
  },
  methods: {
    ...mapMutations([
      'setLangUUID',
    ]),
    changeNowAboutBar(changetype) {
      switch (changetype) {
        case ('ivf'):
          this.nowAboutBar = 'ivf';
          break;
        case ('fertility'):
          this.nowAboutBar = 'fertility';
          break;
        case ('labs'):
          this.nowAboutBar = 'labs';
          break;
        default:
          break;
      }
    },
    initAboutBar() {
      this.aboutBar.ivf = [];
      this.aboutBar.fertility = [];
      this.aboutBar.labs = [];
      getProcreationArticles(
        this.getLangUUID,
        (response) => {
          response.data.data.forEach((elem) => {
            const temp = [
              `/aboutinfo/ivf/${elem.slug}`,
              elem.title,
              elem.icon,
            ];
            this.aboutBar.ivf.push(temp);
          });
        },
      );
      getTraditionalArticles(
        this.getLangUUID,
        (response) => {
          response.data.data.forEach((elem) => {
            const temp = [
              `/aboutinfo/tcm/${elem.slug}`,
              elem.title,
              elem.icon,
            ];
            this.aboutBar.fertility.push(temp);
          });
        },
      );
      getLabsArticles(
        this.getLangUUID,
        (response) => {
          response.data.data.forEach((elem) => {
            const temp = [
              `/aboutinfo/labs/${elem.slug}`,
              elem.title,
              elem.icon,
            ];
            this.aboutBar.labs.push(temp);
          });
        },
      );
    },
  },
  watch: {
    getLangUUID(val) {
      if (val !== null) {
        this.initAboutBar();
      }
    },
  },
  mounted() {
    if (this.getLangUUID !== null) {
      this.initAboutBar();
    }
  },
};
</script>
