export default {

  // 大分類//
  nuwaIvf: '禾馨宜蕴生殖医学中心',
  nuwaTcm: '宜蕴中医诊所',
  nuwaIvfNew: '禾馨大安宜蕴门诊',
  nuwaIvfNew2: '禾馨大安宜蕴门诊',
  nuwaLabs: '禾馨宜蕴胚胎实验室',
  nuwaTeams: '以女性为本的医疗团队',
  nuwaStories: '好孕故事',
  news: '科普与最新消息',
  aboutNuwaPublic: '关于宜蕴医疗',
  visa: '签证申请暨来台流程',
  introTcm: '中医疗程介绍',
  aboutNuwa: '关于我们',
  tcmTitle: '宜蕴中医诊所',

  // 標語 //
  DoctorsSlogan: '禾馨宜蕴 合心宜孕',
  tcmContent: '逾二十年经验丰富的女中医师团队，专精不孕症、养卵、增厚内膜、胚胎植入前后、小产、孕期、经前不适、经痛、过敏体质改善、产后、减重、睡眠障碍等中药调理，并设有独立针灸室，保有治疗期间高度隐密性，提供更为舒适的中医就诊环境，帮助女性朋友在求孕、身体调养这条路上，能走得更为舒适、顺遂！',
  ivfContent: 'HUMANITY、TECHNOLOGY、CARE是宜蕴生殖医学中心的三大核心，以女性为本的整合式健康照护，专业生殖医疗团队，给予医疗科技支持及女性心理层面的全方位照护，重视倾听每位来诊者的声音，提供女性医疗团队服务，透过女性同理心提供医师专业协助，让心的距离更近，诊治过程更有温度，宜蕴为您打造适宜怀孕的方案。',

  // button //
  readAritcal: '阅读完整文章',
  more: '查看更多',
  introTeams: '团队介绍',
  lookingTeams: '查看团队',
  lookingDoctors: '查看医师',
  lookingMore: '了解更多',
  quicklink: '即时咨询',
  moreNuwa: '宜蕴医疗',
  bookingNow: '立即挂号',
  reservation: '立即预约',
  share: '分享',
  sure: '確定',
  message: '讯息',
  copyComplete: '复制完成',
  back: '返回公告',
  backToStory: '返回宜孕故事',
  aboutTreatment: '疗程介绍',
  ivfTreatment: '生殖疗程',
  tcmTreatment: '中医疗程',
  contact: '专人服务',

  // 地址 //
  ivfAddress: '台湾台北市大安区信义路三段149号15楼',
  tcmAddress: '台湾台北市大安区信义路三段149号14楼之一',

  // title //
  oneForOneService: '一对一国际VIP通道服务',

  // time //
  am: '上午',
  noon: '中午',
  pm: '下午',
  night: '晚上',
  betweenNight: '晚间',
  monday: '周一',
  thursday: '周四',
  friday: '周五',
  saturday: '周六',

  // ------header------//

  nuwaIvfHeader: '禾馨宜蕴生殖医学中心',
  nuwaTcmHeader: '宜蕴中医诊所',
  nuwaLabsHeaderHtml: '禾馨宜蕴<br />胚胎实验室',
  nuwaLabsHeader: '禾馨宜蕴胚胎实验室',
  nuwaTeamsHeader: '医疗团队介绍',
  nuwaStoriesHeader: '好孕故事',
  newsHeader: '科普与最新消息',
  aboutNuwaPublicHeader: '关于宜蕴医疗',
  aboutNuwaHeader: '关于我们',
  internationalMedicalCenterHeader: '国际医疗中心',

  language: '语言',
  internationalMedicalCenter: '国际医疗中心',
  booking: '预约挂号',
  login: '登入',
  logout: '登出',

  // ------footer------//
  address: '地址',
  contactText: '预约咨询',
  // privact and reserved
  privacy: '隐私权条款',
  reservedText: '本网站所有内容、资讯及相关权利均为禾馨宜蕴妇产科诊所以及宜蕴中医诊所所有或被授权，任何人未经事前书面同意，不得为任何重制、转载、散布、改作、公开传输、公开发表等侵害智慧财产权或其他权利之行为，并不得将网站全部或部分内容转载于任何形式媒体或社群。本网站委由宜蕴生医股份有限公司维护管理。 ©2021 NUWA FERTILITY CENTER ALL RIGHTS RESERVED.',
  privacyMainTitle: '非常欢迎您光临本网站，为了让您能够安心使用本网站的各项服务与资讯，特此向您说明本网站的隐私权保护政策，以保障您的权益，请您详阅下列内容：',
  privacyTitle1: '一、隐私权保护政策的适用范围',
  parivacyContent1: '隐私权保护政策内容，包括本网站如何处理在您使用网站服务时收集到的个人识别资料。隐私权保护政策不适用于本网站以外的相关连结网站，也不适用于非本网站所委托或参与管理的人员。',
  privacyTitle2: '二、个人资料的搜集、处理及利用方式',
  privacyContent2: '当您造访本网站或使用本网站所提供之功能服务时，我们将视该服务功能性质，请您提供必要的个人资料，并在该特定目的范围内处理及利用您的个人资料；非经您书面同意，本网站不会将个人资料用于其他用途。本网站在您使用服务邮箱、问卷调查等互动性功能时，会保留您所提供的姓名、电子邮箱地址、联络方式及使用时间等。于一般浏览时，伺服器会自行记录相关行径，包括您使用连线设备的IP位址、使用时间、使用的浏览器、浏览及点选资料记录等，做为我们增进网站服务的参考依据，此记录为内部应用，决不对外公布。为提供精确的服务，我们会将收集的问卷调查内容进行统计与分析，分析结果之统计数据或说明文字呈现，除供内部研究外，我们会视需要公布统计数据及说明文字，但不涉及特定个人之资料。',
  privacyTitle3: '三、资料之保护',
  privacyContent3: '本网站主机均设有防火墙、防毒系统等相关的各项资讯安全设备及必要的安全防护措施，加以保护网站及您的个人资料采用严格的保护措施，只由经过授权的人员才能接触您的个人资料，相关处理人员皆签有保密合约，如有违反保密义务者，将会受到相关的法律处分。如因业务需要有必要委托其他单位提供服务时，本网站亦会严格要求其遵守保密义务，并且采取必要检查程序以确定其将确实遵守。',
  privacyTitle4: '四、网站对外的相关连结',
  privacyContent4: '本网站的网页提供其他网站的网路连结，您也可经由本网站所提供的连结，点选进入其他网站。但该连结网站不适用本网站的隐私权保护政策，您必须参考该连结网站中的隐私权保护政策。',
  privacyTitle5: '五、与第三人共用个人资料之政策',
  privacyContent5: '本网站绝不会提供、交换、出租或出售任何您的个人资料给其他个人、团体、私人企业或公务机关，但有法律依据或合约义务者，不在此限。前项但书之情形包括不限于： 经由您书面同意。法律明文规定。为免除您生命、身体、自由或财产上之危险。与公务机关或学术研究机构合作，基于公共利益为统计或学术研究而有必要，且资料经过提供者处理或搜集着依其揭露方式无从识别特定之当事人。当您在网站的行为，违反服务条款或可能损害或妨碍网站与其他使用者权益或导致任何人遭受损害时，经网站管理单位研析揭露您的个人资料是为了辨识、联络或采取法律行动所必要者。有利于您的权益。本网站委托厂商协助搜集、处理或利用您的个人资料时，将对委外厂商或个人善尽监督管理之责。',
  privacyTitle6: '六、Cookie之使用',
  privacyContent6: '为了提供您最佳的服务，本网站会在您的电脑中放置并取用我们的Cookie，若您不愿接受Cookie的写入，您可在您使用的浏览器功能项中设定隐私权等级为高，即可拒绝Cookie的写入，但可能会导至网站某些功能无法正常执行。',
  privacyTitle7: '七、隐私权保护政策之修正',
  privacyContent8: '本网站隐私权保护政策将因应需求随时进行修正，修正后的条款将刊登于网站上。',
  // privact and reserved end

  browser: '最佳浏览体验建议浏览器: Edge、Firefox、Chrome、Safari ( 萤幕设定最佳显示效果为1920*1080 )',

  // ------index------//

  // 疗程
  introIvf: '生殖医学疗程介绍',
  introlabs: '胚胎实验室介绍',
  aboutIntroText: '全女性生殖医疗团队，以女性为本的整合式健康照护，并加入FemTech的新创概念，导入最新生殖细胞储存技术，搭配实证医学营养品疗程，提升试管婴儿成功率，提供大中华地区最好的试管婴儿服务，不论是医疗科技的支持或心理层面的照护。宜蕴为您打造适宜怀孕的方案。',
  labsContent: '优质的胚胎实验室及专业胚胎师是试管婴儿成功与否的关键之一，从精卵结合到胚胎养成每个关键都仰赖细腻分工的实验室团队为您守护，禾馨宜蕴胚胎实验室团队帮助您解决胚胎问题，迎向好孕。',
  // 故事
  storiesConetnt: '姊妹求孕的暖心故事，一路走来的艰辛终将化成喜悦的泪水及怀中带有奶香味的宝宝，宜蕴为您打造适宜怀孕的方案，充满爱包围的专业中心，开启您的幸孕之门。',
  // imgLink区块

  // ------AboutIvf & Tcm & Labs------//
  ivfTitle: '生殖疗程介绍',
  aboutIvfIntro: '以女性为核心的生殖医疗照护，提供全程女医护团队服务选择，妇科私密检查隐私度提升，并导入最新的生殖细胞储存技术与实证学营养品疗程，提升试管婴儿(IVF)成功率，具优越国际医疗能力，提供全大中华地区最好的试管婴儿服务。',
  ivfTeamsTitle: '生殖团队介绍',
  ivfTeamsIntro: '宜蕴生殖医学中心，全女医团队，专注生殖医学，重视倾听每位来诊者的声音，透过女性同理心提供医师专业协助，让心的距离更近，诊治过程更有温度。由前台北医学大学附设医院生殖医学中心副主任及双和医院生殖医学中心主任领军，全盘照护求诊者的生殖之路与身心灵需求。',
  aboutTcmIntro: '逾二十年经验丰富的女中医师团队，专精不孕症调理、养卵、胚胎植入前后调理、小产调理、孕期调理、经期/经痛调理、过敏体质调理、产后调理、睡眠障碍等，设有独立针灸室，保有治疗期间高度隐密性，提供更为舒适的中医就诊环境，帮助女性朋友在求孕、身体调养这条路上，能走得更为顺利!',
  aboutLabsIntro: '国际水准专业度团队，顶尖胚胎实验室，除配有顶级胚胎缩时摄影培养箱(Timelapse)，大幅提升植入胚胎之受孕率，每颗胚胎皆由逾博士级、硕士级胚胎师细心照料，独步全球「胚胎管家制度」每位妈妈配有一位专属胚胎师一条龙全程照顾，用心温暖照料每一颗珍贵的胚胎。',
  tcmTeamsTitle: '中医团队介绍',
  tcmTeamsIntro: '中医调理助您拥有好孕体质，宜蕴中医诊所院长为前北医中医妇科主任陈玉娟，亲身见证生殖技术这二十年进步的观察者，每月门诊求诊人次破千人，诊治案例达万例。',
  labsTitle: '关于胚胎实验室',
  labsIntro: '人才是企业重要资产；资深胚胎师也是生殖医学机构的重要资产。禾馨宜蕴胚胎实验室，由一群认同禾馨医疗及宜蕴医疗理念及经验丰富的硕博士组成。试管婴儿疗程极度仰赖团队合作的细腻分工，尤其是试管婴儿实验室，必须结合高等生命科学技术，及高品质的环境工程。禾馨宜蕴胚胎实验室以技术专业为本，具有三大优势：顶尖胚胎师、智能培养以及安心环境。提供每一位备孕妈妈最专业的技术及最暖心的照护',
  labsTeamsTitle: '实验室团队介绍',
  labsTeamsIntro: '以女性为核心的整合式健康照护，优越的国际医疗能力，FemTech 的新创概念，导入最新的生殖细胞储存技术与Evidence Based 医学营养品疗程，提升IVF 成功率，提供大中华地区最好的试管婴儿服务。',

  // ------About Nuwa------//
  humanity: '人性化照护',
  technology: '医疗科技优势',
  care: '以女性为中心关怀为优先',
  nuwaTeamsContent: '以女性为核心的生殖医疗照护，提供全程女医护团队服务选择，妇科私密检查隐私度提升，并导入最新的生殖细胞储存技术与实证学营养品疗程，提升试管婴儿(IVF)成功率，具有优越的国际医疗能力，提供全大中华地区最好的试管婴儿服务。',
  aboutLabs: '人才是企业重要资产；资深胚胎师也是生殖医学机构的重要资产。禾馨宜蕴胚胎实验室，由一群认同禾馨医疗及宜蕴医疗理念及经验丰富的硕博士组成。试管婴儿疗程极度仰赖团队合作的细腻分工，尤其是试管婴儿实验室，必须结合高等生命科学技术，及高品质的环境工程。禾馨宜蕴胚胎实验室以技术专业为本，具有三大优势：顶尖胚胎师、智能培养以及安心环境。提供每一位备孕妈妈最专业的技术及最暖心的照护。',
  advantage: '我们的优势',
  advantageContent: '打造亚洲第一座以女性为本的生殖医学中心，提供备孕夫妻求子路上有温度的问诊，舒适且具隐私的环境，并提供中西医诊治及生殖咨询师的心理咨商服务。',
  womenCare: '全女性医疗',
  tcmCare: '中医助孕',
  customizeCare: '量身打造怀孕方案',
  psyCare: '心理諮商',
  technologyCare: '科技照护平台',
  vipCare: '设VIP门诊',
  labsCare: '顶尖胚胎实验室',
  femTech: 'FemTech概念',
  location: '就诊地点',
  ivfLocation: '捷运大安站一号出口，台北市农会信义大楼，搭乘电梯至15楼',
  openTime: '营业时间',
  tcmLocation: '捷运大安站一号出口，台北市农会信义大楼，搭乘电梯至14楼',
  parking: '停车资讯',
  parkingLocation: '附中公园地下停车场台北市大安区复兴南路一段340巷11号',
  parkingLocation_2: 'iRent 台北农会中正场台北市大安区复兴南路一段 340 巷',
  parkingLocation_3: '大安高工地下停车场（信义路3段166巷6弄12号地下）共有526个车位，含身心障碍停车位11格)，位于诊所正对面巷子。',
  // ------International------//
  visaProcess: '签证办理',
  professional: '专业可靠',
  oneForOne: '一对一通道',
  personService: '专人服务',
  medicalService: '医疗服务',
  englishService: '全英文服务',
  contactUs: '联系我们',
  readyDocuments: '准备资料',
  provideDocuments: '提供资料',
  contactUsAndComing: '联系我们，预约宝宝的到来',
  oneForOneServiceContent: '专门办理「国际特约医疗」与「自费医疗」提供快速便捷，无语言隔阂的医疗服务。包含，特约门诊、旅游咨询、来台生活资讯提供等。',
  thisIsNotInsurance: '本中心不属于台湾全民健保给付范围',
  dealVisaOrEntry: '办理医疗签证或办理入台证',
  dealVisaOrEntryContent: '宜蕴医疗团队确认您的状况后，会有专人与您联系确认入境签证所需的协助事项、预约安排的时间、确认您入境当天如何抵达住宿地点、看诊当天如何抵达宜蕴。',
  dealVisaAndSteps: '签证申请暨来台流程',
  stepOneMidTitle: '梦想总是开始在第一步',
  stepOneInfo: '在您到台湾开始生殖医学之旅前，请与我们的国际医疗中心咨询团队联系，我们会协助评估您的状况及安排疗程。我们会有专人协助您办理医疗签证或者办理入台证。',
  stepTwoeMidTitle: '小文件 大作用',
  stepTwoInfoParam1: '我们需会要请您提供一些文件，来评估及安排您的疗程。',
  stepTwoInfoParam2: '夫妻双方的护照。 (大陆地区居民请提供往来台湾通行证)',
  stepTwoInfoParam3: '中文或英文的结婚证明*2',
  stepTwoInfoParam4: '过往相关病历资料或影像报告',
  stepThreeeMidTitle: '送出资料 预约梦想的开始',
  stepThreeInfoParam1: '将准备好的文件提供给我们，',
  stepThreeInfoParam2: '我们会在1-3个工作天内与您联系。',
  stepThreeInfoParam3: '大陆地区居民入境台湾是使用入台证。',
  stepThreeInfoParam4: '根据台湾的《台湾人工繁殖法》，您会需要准备结婚证明，才能在台湾进行生殖医学疗程。',
  // ------News------//
  all: '全部',
  notFound: '查无文章',
  // ------Registration------//
  onlineBooking: '网上挂号',
  selectList: '请选择看诊项目',
  specialOutpatient: '特需门诊',
  OutpatientInfo: '利用本院「网上挂号系统」进行预约，以减少您在现场等候的时间',
  amOutpatient: '上午门诊',
  noonOutpatient: '下午门诊',
  nightOutpatient: '晚间门诊',
  bookingInfo: '网路挂号预约时间为30天(含当日门诊)，每日00:00更新※各诊若人数多需延诊时，上述各项结束时间配合延长',
  bookingInfoPrecaution: '挂号注意事项',
  bookingInfoPrecautionContent1: '报到时间：上午门诊11:00前报到、下午门诊16:00前报到、夜间门诊20:00前报到',
  bookingInfoPrecautionContent2: '本院各项费用若有调整将不另行通知，以现场费用为准。宜蕴中医诊所为全自费门诊。',
  bookingInfoPrecautionContent3: '若您并非本国国民(具有护照、身分证或健保卡)，将依本院国际医疗标准收费；药费、检验、检查及其他医疗处置费用等另按本院国际医疗服务价格收费。',
  bookingInfoPrecautionContent4: '如果您希望对本院相关费用或本院国际医疗相关费用有更多了解，欢迎与我们连络。',
  bookingInfoPrecautionContent5_1: '当天门诊网路挂号截止时间:',
  bookingInfoPrecautionContent5_2: '上午门诊 10:00',
  bookingInfoPrecautionContent5_3: '下午门诊 15:30',
  bookingInfoPrecautionContent5_4: '夜間门诊 19:00',
  bookingInfoPrecautionContent5_5: '已预约之当天门诊不接受网路退挂，请来电退挂。',
  bookingInfoPrecautionContent6_1: '网上挂号时间 ：全年24小时服务，请多善加利用。',
  bookingInfoPrecautionContent6_2: '门诊时间若有异动，请以当月门诊时间表为准，谢谢！',
  clinicCallNumberRule: '诊间叫号原则',
  clinicCallNumberContent1: '看诊依号码顺序叫号，但必须完成报到手续，才会叫看诊号。',
  clinicCallNumberContent2: '过号叫诊以报到顺序为依据，到诊时若号码已过号，请至柜台报到由柜台通知诊间安排顺序。',
  clinicCallNumberContent3: '过号叫号原则如下：每2位安排1位过号病人。',
  clinicTcmNotify: '宜蕴中医贴心提醒',
  clinicTcmNotifyContent_1: '为了维护良好的就医品质，可于每次就诊前事先备好他院检验报告或数据、基础体温数据或疗程进行单，进诊间时交给医师方便评估。',
  clinicTcmNotifyContent_2: '为了节省您宝贵的看诊时间，请事先查询好您下一次预约回诊时间或可预约回诊时间。',
  clinicTcmNotifyContent_3: '宜蕴中医视病如亲，每一位贵宾的看诊时间不一定，需要您的耐心等候，如因故无法等候可请柜台人员协助改约时间。',
  clinicTcmNotifyContent_4: '因covid 19疫情，看诊须全程配戴口罩，中医门诊就诊前请先在家拍好全脸及舌头照片以协助医师诊断',
  nuwaSpecialOutpatient: '宜蕴特需门诊',
  nuwaSpecialOutpatientInfo_1: '宜蕴特需门诊为了追求卓越的生殖医学服务，为了让您能轻松且迅速地安排您的生殖医学疗程',
  nuwaSpecialOutpatientInfo_2: '宜蕴团队针对您个人需求，规划设计专属于您的VIP服务计划。',
  clinicServiceTitle: '看诊服务内容',
  clinicServiceContent: '特约门诊提供您尊荣的门诊服务，由专业亲切的宜蕴团队为您安排您的生殖医学疗程，只要一通电话，就能在舒适、宁静的医疗空间中，拥有宜蕴坚强的生殖医学团队所提供的服务，让您享受个人专属精致的生殖医学服务。',
  phoneBooking: '专线约诊',
  phoneBookingContent: '按下宜蕴特约门诊预约电话，告知您的时间与需求，即有专人为您预约，并安排适当主治医师。',
  personReception: '专人接待',
  personReceptionContent: '只要您告知预定到达的时间，我们将提供专人协助就医流程。',
  vipSpace: '专属空间',
  vipSpaceContent: '设有专属舒适的咨询空间，并提供您舒适且独立的等候空间。',
  accompany: '专属陪伴',
  accompanyContent: '由宜蕴团队专人全程陪伴您看诊做检查、批价、领药亦由宜蕴团队为您代办。',
  personReceptionContent_2: '宜蕴团队将依您的疗程结果，主动联系回报您的疗程状况，作为您专属的健康管理师。',
  clinicRule: '看诊须知',
  clinicRuleContent1: '宜蕴特需门诊采约诊制。',
  clinicRuleContent2: '宜蕴特需门诊将透过咨询了解您的需求并做适切的安排，因此预约的时段将以宜蕴特约门诊告知为准，欢迎洽询。',
  clinicRuleContent3: '宜蕴特需门诊各项服务均属于自费。',
  clinicRuleContent4: '宜蕴特约门诊费用基本为每30分钟新台币2,000元，逾时依照此计费方式另行收费，请依个人需求预约合适时段(含挂号费、医师基本诊察与咨询费用)，其他医疗相关费用及检查费用另计。',
  specialClinicPhoneNumber: '特需门诊约诊电话',
  local: '国內',
  oversea: '国外',
  // ------RegistrationChoose------//
  schedule: '门诊总表',
  doctor: '医师',
  lastWeek: '上一周',
  nextWeek: '下一周',
  overTime: '已超时',
  book: '预约',
  return: '返回',
  confirmOutpatient: '确认门诊',
  name: '姓名',
  datnamePlaceHoldere: '请输入您的姓名',
  date: '生日',
  uid: '身分证字号',
  uidInfo: '与护照/居留证号码择一填写',
  passportNum: '护照/居留证号码',
  passportNumInfo: '与身分证字号择一填写',
  contactNumber: '联络电话',
  contactPlaceholder: '请输入联络电话号码（国碼）',
  emailPlaceholder: '请输入邮箱帐号',
  verification: '验证码',
  verificationPlaceholder: '请输入验证码',
  sendVerificationByTextMsg: '简讯发送验证码',
  sendVerificationByMail: '邮件发送验证码',
  verificationInfo: '输入图片显示数字加总 以取得验证码',
  verificationAnswerPlaceholder: '请输入答案',
  GgetVerification: '取得验证码',
  nextStep: '下一步',
  changePassword: '变更密码',
  newPasswordInfo: '＊新密码须为英数混合8码以上，且含1大写字母',
  newPassword: '新密码',
  comfirmNewPassword: '确认新密码',
  saveMod: '储存修改',
  hello: '您好',
  overSeaServiceInfo: '非本国人客户挂号，目前会由专人为您服务，欢迎您于以下时间来电',
  IvfClinic: '宜蕴生殖医学中心门诊',
  TcmClinic: '宜蕴中医诊所门诊',
  passwordError: '密码必须有8 个字元，并包含大、小写字母、数字，其中一个字母需为大写',
  passwordComfirmError: '确认密码与密码不符',
  modifySuccess: '更改成功',
  noDoctor: '无法取得医师资讯',
  selectDoctorInfo: '请先选择挂号医师',
  selectTimeInfo: '请选择预约时段',
  noDoctorSchedule: '无法取得医师班表',
  customSWALSelectDoctor: '请选择挂号医师',
  customSWALSelectTime: '请选择预约时段',
  customSWALFillName: '请填写姓名',
  customSWALFillDate: '请填写生日',
  customSWALFillPid: '请填写身分证 或 居留证/护照号码号码',
  customSWALPidError: '身分证字号格式错误',
  customSWALSelectNumberLocal: '请选择手机区码',
  customSWALFillNumber: '请填写手机号码',
  customSWALFillMail: '请填写邮箱',
  customSWALClickVerification: '请点击获取验证码',
  customSWALFillVerification: '请输入验证码',
  customSWALMailError: '请输入邮箱地址',
  customSWALPhoneError: '请输入手机号码',
  sendingVerification: '验证码已送出',
  successfulRegistration: '注册成功',
  // ------RegistrationConfirm------//
  comfirmClinic: '请确认门诊',
  doNotRefresh: '请勿重整页面避免资料遗失',
  modifyOutpatient: '修改门诊',
  refreshError: '由于页面重整导致资料遗失，请重新挂号',
  lostColumns: '缺少必要栏位值, 请重新挂号',
  // ------RegistrationComplete------//
  registrationClinic: '预约门诊',
  selectDoctorAndTime: '选择医师/时段',
  comfirmRegistration: '确认预约',
  registrationComplete: '挂号完成',
  registrationSuccess: '预约成功',
  registrationDate: '日期',
  registrationTime: '时段',
  registrationCategory: '诊别',
  room: '诊间',
  roomNum: '诊',
  number: '号码',
  yourName: '名字',
  passportOrEntryPassNum: '护照/居留证号',
  comingInfo: '就诊时请携带身分证明文件。',
  backToIndex: '回首页',
  myregistration: '我的预约',
  ivf: '生殖医学',
  tcm: '中医',
  one: '一',
  Two: '二',
  Three: '三',
  Four: '四',
  Five: '五',
  Six: '六',
  Seven: '七',
  Eight: '八',
  dataError: '资料取得失败',
  // ------memberSignup------//
  signup: '会员注册',
  gender: '性別',
  female: '女性',
  male: '男性',
  accout: '帐号',
  pidOrPassportNum: '身分证字号/护照号码',
  Password: '密码',
  passwordPlaceholder: '请输入8-20位数之密码，须包含一个大写英文字母',
  confirmPassword: '确认密码',
  refillPasswordPlaceholder: '请再输入一次密码',
  getVerificationByTextMail: '获取简讯验证码',
  getVerificationByEMail: '获取Email验证码',
  agree: '我同意',
  areadyHasAccout: '已经有会员了',
  customSWALInputName: '请输入姓名',
  customSWALInputBirth: '请输入生日',
  customSWALInputGender: '请选择性别',
  customSWALInputAccount: '请输入帳號',
  customSWALInputPassword: '请输入密码',
  customSWALComfirmPassword: '请确认密码',
  customSWALInputNumber: '请输入连络电话',
  customSWALInputCorrectNumber: '请输入正确的连络电话',
  customSWALInputLocal: '请输入区码',
  customSWALAgreePrivacy: '请同意隐私权条款',
  // ------memberLogin------//
  memberLogin: '会员登入',
  loginError: '登入失败，帐密是否有误?',
  loginAccountPlaceholder: '帐号为身份证字号',
  loginPasswordPlaceholder: '请输入密码',
  rememberMe: '记住我',
  fogotPassword: '忘记密码?',
  // ------memberHome------//
  myInfo: '基本资料',
  pleaseLogin: '请先登入',
  // ------fogotPassword------//
  fogotPasswordTitle: '输入个人资讯重设密码',
  fogotPasswordInfo: '请输入您的个人资料，系统将寄送重设密码信至您的电子邮箱。',
  pleaseInputBirthDay: '请输入生日',
  resetPasswordTextMail: '传送重设密码简讯',
  resetPasswordEmail: '传送重设密码邮件',
  hasAccount: '已经有帐户？',
  mailSendingTitle: '已传送重设密码的电子邮箱',
  mailSendingContent: '已将电子邮件传送至您输入的邮箱，<br> 依照电子邮箱中指示重设密码。',
  textMailSendingTitle: '已传送重设密码的简讯',
  textMailSendingContent: '已将简讯传送至09xxxxxxxx，<br> 请依照简讯中指示重设密码。',
  memberNotFound: '查无此会员资料',
  complete: '完成',
  // ------memberData------//
  helloGuest: '贵宾，您好',
  // ------memberReserveList------//
  cancelReserve: '取消预约',
  pidAndPassportNum: '护照/居留证号',
  noRigistration: '尚未有挂号资料',
  comfirmCancel: '确认取消',
  cancelSucsess: '门诊取消成功',
  // ------memberDataInfo------//
  patientNo: '病历号码',
  phoneNumber: '手机号码',
  birthDay: '出生日期',
  memberDataPid: '身分证号',
  oldPassword: '原密码',
  passwordLengthError: '密码必须有8个字元',
  inputOldPassword: '请输入旧密码',
  // 团队分类
  生殖醫療團隊: '生殖医疗团队',
  麻醉醫師團隊: '麻醉医师团队',
  乳房外科團隊: '乳房外科团队',
  護理諮詢團隊: '护理咨询团队',
  中醫醫療團隊: '中医医疗团队',
  藥師: '药师',
  實驗室團隊: '实验室团队',
  // 我的預約
  roomOne: '一诊',
  roomTwo: '二诊',
  roomThree: '三诊',
  roomFour: '四诊',
  roomFive: '五诊',
  roomSix: '六诊',
  roomSeven: '七诊',
  roomEight: '八诊',
  // 後端回傳
  reserved401: '您本日已有预约挂号，请勿重复预约',
  reserved402: '本周已有预约挂号纪录',
  verify400: '验证失败',
  verify401: '图形验证码请勿空白',
  // 我的預約
  num: '号',
};
