<template>
  <footer>
    <div class="w1400">
      <div class="container">
        <div class="info col30">
          <div class="contact">
            <div class="title_link">
              <p>{{$t('nuwaIvf')}}</p>
              <ul class="sns">
                <li>
                  <a href="https://www.facebook.com/%E5%AE%9C%E8%98%8A%E5%A9%A6%E7%94%A2%E7%A7%91%E8%A8%BA%E6%89%80-%E7%94%9F%E6%AE%96%E9%86%AB%E5%AD%B8-105335891590657/" target="_blank">
                    <img src="@/assets/img/communication_1.svg" alt="" />
                  </a>
                </li>
                <li>
                  <a @click="checkDeviceAndOpen(3, 'https://line.me/R/ti/p/%40232ecljp')">
                    <img src="@/assets/img/communication_2.svg" alt="" />
                  </a>
                </li>
                <li>
                  <a @click="checkDeviceAndOpen(1, 'https://wa.me/qr/HP4CITFXVXMFL1')">
                    <img src="@/assets/img/communication_3.svg" alt="" />
                  </a>
                </li>
                <li>
                  <a @click="checkDeviceAndOpen(2, 'http://weixin.qq.com/r/zhMAGNnEZIQbrZ1790bc')">
                    <img src="@/assets/img/communication_4.svg" alt="" />
                  </a>
                </li>
              </ul>
            </div>
            <small>
              {{$t('address')}} | {{$t('ivfAddress')}}
            </small>
            <small>
              {{$t('contactText')}} | <a href="tel:02-2706-5288">(02)2706-5288</a>
            </small>
            <br>
            <div class="title_link">
              <p>{{$t('nuwaTcm')}}</p>
              <ul class="sns">
                <li>
                  <a href="https://www.facebook.com/%E5%AE%9C%E8%98%8A%E4%B8%AD%E9%86%AB%E8%A8%BA%E6%89%80-103647415015823/" target="_blank">
                    <img src="@/assets/img/communication_1.svg" alt="" />
                  </a>
                </li>
                <li>
                  <a @click="checkDeviceAndOpen(4, 'https://line.me/R/ti/p/%40627jlyse')">
                    <img src="@/assets/img/communication_2.svg" alt="" />
                  </a>
                </li>
                <li>
                  <a @click="checkDeviceAndOpen(1, 'https://wa.me/qr/HP4CITFXVXMFL1')">
                    <img src="@/assets/img/communication_3.svg" alt="" />
                  </a>
                </li>
                <li>
                  <a @click="checkDeviceAndOpen(2, 'http://weixin.qq.com/r/zhMAGNnEZIQbrZ1790bc')">
                    <img src="@/assets/img/communication_4.svg" alt="" />
                  </a>
                </li>
              </ul>
            </div>
            <small>
              {{$t('address')}} | {{$t('tcmAddress')}}
            </small>
            <small>
              {{$t('contactText')}} | <a href="tel:02-2706-5277">(02)2706-5277</a>
            </small>
          </div>
        </div>
        <ul class="links col70">
          <li v-for="(item, key) in footerNav" :key="key">
            <span v-for="(item, key) in item.title" :key="key">
              <router-link
              class="txt-medium"
              v-if="item[1] !== null"
              :to="item[1]">
                {{item[0]}}
              </router-link>
              <h6 style="font-size: 1rem" v-else class="txt-medium">{{item[0]}}</h6>
            </span>
            <ul class="sub-menu">
              <li>
                <template v-for="(item, key) in item.subTitle">
                  <a
                  v-if="item[0] === '專人服務'"
                  :key="key"
                  :href="`mailto:${item[1]}`">
                    {{item[0]}}
                  </a>
                  <router-link
                  v-else
                  :key="key"
                  :to="item[1]">
                    {{item[0]}}
                  </router-link>
                </template>
              </li>
            </ul>
          </li>
          <!-- <li class="download_block">
            <a href="#" target="_blank" rel="noreferrer noopener">
              <img src="https://picsum.photos/id/1024/120/40" alt="">
            </a>
            <a href="#" target="_blank" rel="noreferrer noopener">
              <img src="https://picsum.photos/id/1024/120/40" alt="">
            </a>
          </li> -->
        </ul>
      </div>
      <div class="copyright">
        <p class="supersmall txt-regular">
          ©2021 NUWA FERTILITY ALL RIGHTS RESERVED. <font-awesome-icon :icon="['far', 'plus-square']" class="h5 plus_square" @click="openLightbox1" />
        </p>
        <p class="supersmall txt-regular privacy_btn" @click="openLightbox2">
          {{$t('privacy')}}
        </p>
      </div>
      <div class="copyright browser" style="border: none;">
        <p class="supersmall txt-regular" style="padding-top:0;">{{$t('browser')}}</p>
        <span class="lang">
          <p
          @click="changeLang('tw')"
          class="supersmall txt-regular lang_btn" style="padding-top:0;">
            <font-awesome-icon icon="globe" />
            繁體中文
          </p>
          <p
          @click="changeLang('cn')"
          class="supersmall txt-regular lang_btn" style="padding-top:0;">
            <font-awesome-icon icon="globe" />
            简体中文
          </p>
          <p
          @click="changeLang('en')"
          class="supersmall txt-regular lang_btn" style="padding-top:0;">
            <font-awesome-icon icon="globe" />
            English
          </p>
        </span>
      </div>
    </div>

    <div id="privacy_lightbox2" v-show="ifLightbox1">
      <div class="cover" @click="closeLightbox1"></div>
      <div class="box">
        <img src="@/assets/img/delete.svg" class="close"  @click="closeLightbox1">
        <div class="privacy_box">
          <div class="txt-black p">
          {{$t('reservedText')}}
          </div>
        </div>
      </div>
    </div>

    <div id="privacy_lightbox2" class="privacy_only" v-show="ifLightbox2">
      <div class="cover" @click="closeLightbox2"></div>
      <div class="box">
        <img src="@/assets/img/delete.svg" class="close"  @click="closeLightbox2">
        <div class="privacy_box">
          <div class="txt-black p">
          {{$t('privacyMainTitle')}}
          <br><div class="privacy_title h4">{{$t('privacyTitle1')}}</div><br>
          {{$t('parivacyContent1')}}
          <br><div class="privacy_title h4">{{$t('privacyTitle2')}}</div><br>
          {{$t('privacyContent2')}}
          <br><div class="privacy_title h4">{{$t('privacyTitle3')}}</div><br>
          {{$t('privacyContent3')}}
          <br><div class="privacy_title h4">{{$t('privacyTitle4')}}</div><br>
          {{$t('privacyContent4')}}
          <br><div class="privacy_title h4">{{$t('privacyTitle5')}}</div><br>
          {{$t('privacyContent5')}}
          <br><div class="privacy_title h4">{{$t('privacyTitle6')}}</div><br>
          {{$t('privacyContent6')}}
          <br><div class="privacy_title h4">{{$t('privacyTitle7')}}</div><br>
          {{$t('privacyContent8')}}
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import { mapMutations } from 'vuex';
import VueCookies from 'vue-cookies';

export default {
  name: 'Footer',
  data() {
    return {
      isMobile,
      ifLightbox1: false,
      ifLightbox2: false,
      footerNav: [
        {
          title: [
            [this.$t('aboutNuwaPublic'), '/about/nuwa'],
          ],
          subTitle: [
            [this.$t('nuwaIvf'), '/about/ivf'], [this.$t('nuwaTcm'), '/about/tcm'], [this.$t('nuwaLabs'), '/about/lab'],
          ],
        },
        {
          title: [
            [this.$t('aboutTreatment'), null],
          ],
          subTitle: [
            [this.$t('ivfTreatment'), '/about/ivf'], [this.$t('tcmTreatment'), '/about/tcm'],
          ],
        },
        {
          title: [
            [this.$t('internationalMedicalCenter'), '/international'],
          ],
          subTitle: [
            [this.$t('visa'), '/international'], [this.$t('contact'), 'ivfconsult@nuwacare.com'],
          ],
        },
        {
          title: [
            [this.$t('nuwaStories'), '/story/1'],
          ],
        },
        {
          title: [
            [this.$t('news'), '/news/all/1'],
          ],
        },
      ],
    };
  },
  methods: {
    ...mapMutations([
      'setSocialLightbox',
      'setBodylock',
    ]),
    checkDeviceAndOpen(type, url) {
      if (this.isMobile) {
        window.open(url, '_blank');
      } else {
        const data = {
          status: true,
          type,
        };
        this.setSocialLightbox(data);
        this.setBodylock(true);
      }
    },
    openLightbox1() {
      this.ifLightbox1 = true;
      const el = document.getElementsByTagName('body')[0];
      el.classList.add('lock');
    },
    closeLightbox1() {
      this.ifLightbox1 = false;
      const el = document.getElementsByTagName('body')[0];
      el.classList.remove('lock');
    },
    openLightbox2() {
      this.ifLightbox2 = true;
      const el = document.getElementsByTagName('body')[0];
      el.classList.add('lock');
    },
    closeLightbox2() {
      this.ifLightbox2 = false;
      const el = document.getElementsByTagName('body')[0];
      el.classList.remove('lock');
    },
    changeLang(lang) {
      switch (lang) {
        case 'tw':
          VueCookies.set('lang', 'zh-tw');
          break;
        case 'cn':
          VueCookies.set('lang', 'zh-hans-cn');
          break;
        case 'en':
          VueCookies.set('lang', 'en-');
          break;
        default:
          break;
      }
      this.$router.go();
    },
  },
  mounted() {
    console.log('isMobile: ', isMobile);
  },
  watch: {
    /** 換路徑即關閉footer lightbox */
    $route() {
      this.ifLightbox1 = false;
      this.ifLightbox2 = false;
    },
  },
};
</script>
