<template>
  <div class="socialLightbox" v-show="socialLightbox.status">
    <div class="cover" @click="clickCloseLightbox()"></div>
    <div class="box">
      <!-- 原始關閉圖案是放在圖片裡面, 因此只有click method -->
      <div v-if="socialLightbox.type !== 0" class="fake_close_btn close" @click="clickCloseLightbox()"></div>
      <!-- line qr code頁面是切版, 因此close btn會是獨立的 -->
      <img v-else src="@/assets/img/delete.svg" class="fake_close_btn close closeLine"  @click="clickCloseLightbox()">
        <template v-if="socialLightbox.type === 0">
          <div class="txt-center line_box">
            <h4 class="txt-bold qr_title">透過行動條碼加入LINE@好友
              <img src="@/assets/img/deco_flower.svg" alt="" class="deco_flower flower_scale"></h4>
            <br>
            <p class="txt-dark_gray small txt-description">
              方法１
              <br />
              點擊行動條碼圖示，可立即前往官方 LINE 加入好友
              <br />
              方法２
              <br />
              掃描行動條碼：開啟 LINE 應用程式，點選「加入好友」，選擇「行動條碼」即可掃描加入好友
            </p>
            <div class="qr_box">
              <div class="ivf_box">
                <a href="https://line.me/R/ti/p/%40232ecljp" target="_blank">
                  <img src="@/assets/img/ivf_qrcode.png" />
                  <h5 class="txt-theme">
                    宜蘊婦產科
                  </h5>
                </a>
              </div>
              <div>
                <a href="https://line.me/R/ti/p/%40627jlyse" target="_blank">
                  <img src="@/assets/img/tcm_qrcode.png" />
                  <h5 class="txt-theme">
                    宜蘊中醫診所
                  </h5>
                </a>
              </div>
            </div>
          </div>
        </template>
        <!-- <img v-show="socialLightbox.type === 0" src="@/assets/img/LINE@.jpg" /> -->
        <img v-show="socialLightbox.type === 1" src="@/assets/img/WhatsApp.jpg" />
        <img v-show="socialLightbox.type === 2" src="@/assets/img/weChat.jpg" />
        <img v-show="socialLightbox.type === 3" src="@/assets/img/LINE@ivf.jpg" />
        <img v-show="socialLightbox.type === 4" src="@/assets/img/LINE@tcm.jpg" />
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'SocialLightbox',
  data() {
    return {
    };
  },
  computed: {
    ...mapState([
      'socialLightbox',
      'bodyLock',
    ]),
  },
  watch: {
    bodyLock(v) {
      const body = document.getElementsByTagName('body')[0];
      if (v) {
        body.classList.add('lock');
      } else {
        body.classList.remove('lock');
      }
    },
  },
  methods: {
    ...mapMutations([
      'setSocialLightbox',
      'setBodylock',
    ]),
    clickCloseLightbox() {
      this.setSocialLightbox({ status: false });
      this.setBodylock(false);
    },
  },
};
</script>
